<template>
  <div class="detail">
    <div class="container">
      <Menu :active="2"/>
      <div class="main">
        <Header @getContract="getContract"/>
        <div class="welcome">
          <span>Check,</span> Order Details
        </div>
        <div class="banner bWrap">
          <div class="bg">
            <div class="voucher">
              <img src="@/assets/imgs/icon_order.png">
              <div class="text">Product Information</div>
            </div>
            <img class="pro" :src="orderInfo.pc_image">
            <div class="right">
              <div class="text">
                <div class="name">Product: {{ orderInfo.name }}</div>
                <div>Order Date: {{ orderInfo.add_time }}</div>
              </div>
              <img :src="orderInfo.images">
            </div>
          </div>
        </div>
        <div class="info">
          <div class="left bWrap">
            <div class="title">
              <img src="@/assets/imgs/icon_mine.png">
              <div class="text">Receiver Information</div>
            </div>
            <div class="content">
              <div class="item">
                <div class="label">Receiver</div>
                <div class="value">{{ orderInfo.u_name }}</div>
              </div>
              <div class="item">
                <div class="label">International Mobile Number</div>
                <div class="value">{{ orderInfo.mobile }}</div>
              </div>
              <div class="item">
                <div class="label">Login E-mail</div>
                <div class="value">{{ orderInfo.login_email }}</div>
              </div>
              <div class="item">
                <div class="label">Contact E-mail</div>
                <div class="value">{{ orderInfo.email }}</div>
              </div>
              <div class="item big">
                <div class="label">Wallet Address</div>
                <div class="value">{{ orderInfo.receiving_address }}</div>
              </div>
              <div class="item big">
                <div class="label">Address</div>
                <div v-if="orderInfo.country_name" class="value">
                  {{ orderInfo.country_name + ' ' + orderInfo.area_line_1 + ' ' + orderInfo.area_line_2 + ' ' + orderInfo.city + ' ' + orderInfo.province }}
                </div>
              </div>
            </div>
          </div>
          <div class="right bWrap">
            <div class="title">
              <img src="@/assets/imgs/icon_order.png">
              <div class="text">Order Status</div>
            </div>
            <div class="step">
              <div class="item">
                <div class="text">
                  <div class="circle"></div>
                  <div>Product Redeemed</div>
                </div>
                <div class="time">{{ orderInfo.add_time }}</div>
                <div class="line"></div>
              </div>
              <div class="item">
                <div class="text">
                  <div class="circle"></div>
                  <div>Status</div>
                  <a-tooltip placement="right" color="#3b3b3a">
                    <template #title>Please contact our customer<br/>services for more information.</template>
                    <img src="@/assets/imgs/icon_tips.png">
                  </a-tooltip>
                </div>
                <div v-if="orderInfo.order_status == 1" class="time">Shipping</div>
                <div v-if="orderInfo.order_status == 2" class="time">Successfully Redeemed</div>
                <div v-if="orderInfo.order_status == 3" class="time">Failed</div>
                <div class="line"></div>
              </div>
              <div class="item">
                <div class="text">
                  <div class="circle"></div>
                  <div>Tracking Number</div>
                </div>
                <div class="time">
                  {{ orderInfo.shipping_code != 0 ? orderInfo.shipping_code : 'Data Not Found'}}
                </div>
              </div>
            </div>
            <a-tooltip placement="left" color="#3b3b3a">
              <template #title>Contact Customer Service</template>
              <img class="service" src="@/assets/imgs/icon_service.png" @click="openZendesk">
            </a-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { useRoute } from 'vue-router'
  import { ref } from 'vue'
  import { orderDetail } from '@/request/api'

  const route = useRoute()
  const order_id = route.query.id
  const orderInfo = ref({})

  function getContract(obj) {
    orderDetail({
      address: obj.address,
      order_id
    }).then(res => {
      if(res.success) {
        orderInfo.value = res.data
      }
    })
  }

  function openZendesk() {
    zE('webWidget', 'show');
    zE('webWidget', 'open');
  }
</script>

<style lang="less" scoped>
.detail{
  min-height: 100vh;
  background: linear-gradient(180deg, #1B1B1B 53.13%, #0D0D0D 100%);

  .container {
    width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    justify-content: space-between;

    .main {
      width: 888px;
      padding-top: 20px;

      .welcome{
        font-size: 33px;
        margin: 54px 0 27px 0;

        span{
          color: #697377;
        }
      }
      .banner {
        height: 235px;
        margin-bottom: 22px;

        .bg{
          height: 100%;
          background: url("~@/assets/imgs/productL.png") no-repeat;
          background-size: auto 100%;
          position: relative;
          padding: 18px 0 0 20px;

          .voucher{
            display: flex;
            align-items: center;
            margin-bottom: 22px;

            img{
              width: 26px;
              margin-right: 15px;
            }
            .text{
              font-size: 17px;
            }
          }
          .pro{
            width: 164px;
            height: 155px;
            margin-left: 60px;
          }
          .right{
            position: absolute;
            display: flex;
            align-items: center;
            right: 24px;
            top: 50%;
            transform: translateY(-50%);

            .text{
              font-size: 18px;
              color: #06C8A1;
              text-align: right;

              .name{
                margin-bottom: 16px;
              }
            }
            img{
              width: 60px;
              margin-left: 18px;
              border-radius: 14px;
            }
          }
        }
      }
      .info{
        display: flex;
        justify-content: space-between;

        .title{
          display: flex;
          align-items: center;

          img{
            width: 21px;
            margin-right: 15px;
          }
          .text{
            font-size: 17px;
          }
        }
        .left{
          width: 592px;
          min-height: calc(100vh - 467px);
          padding: 24px;

          .content{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .item{
              width: 250px;
              margin-top: 18px;
              border-bottom: 0.7px solid #06C8A1;
              padding-bottom: 2px;

              .label{
                font-size: 12px;
                line-height: 18px;
                color: #06C8A1;
              }
              .value{
                font-size: 14px;
                line-height: 23px;
              }
            }
            .big{
              width: 100%;
            }
          }
        }
        .right{
          width: 275px;
          min-height: calc(100vh - 467px);
          padding: 24px;
          position: relative;
          background-image: linear-gradient(180deg,  #000000, #000000), linear-gradient(180deg, #06C8A1, transparent 60%, transparent 100%);

          .title{
            margin-bottom: 14px;

            img{
              width: 24px;
            }
          }
          .step{
            padding-left: 6px;

            .item{
              position: relative;
              margin-bottom: 14px;

              .text{
                font-size: 14px;
                line-height: 22px;
                display: flex;
                align-items: center;

                .circle{
                  width: 7px;
                  height: 7px;
                  border-radius: 50%;
                  background-color: #06C8A1;
                  margin-right: 14px;
                }
                img{
                  width: 10px;
                  margin-left: 10px;
                }
              }
              .time{
                color: #697377;
                font-size: 12px;
                line-height: 18px;
                padding-left: 24px;
              }
              .line{
                width: 1px;
                height: 45px;
                background-color: #06C8A1;
                position: absolute;
                top: 12px;
                left: 3.5px;
              }
              .second{
                top: 25px;
              }
            }
          }
          .service{
            position: absolute;
            width: 22px;
            right: 20px;
            bottom: 20px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
